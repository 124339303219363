var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("iframe", {
    ref: "frame",
    staticStyle: {
      width: "100%",
      height: "1000px"
    },
    attrs: {
      id: "iframe",
      src: "../../../../tableu.html",
      scrolling: "no",
      frameborder: "0"
    },
    on: {
      load: _vm.sendMessage
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };